



import Vue from 'vue';
import Component from 'vue-class-component';
import GenericContent from '@/components/content/GenericContent.vue';

@Component({
  components: {
    GenericContent,
  },
})
export default class Management extends Vue {

}
